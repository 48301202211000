<script lang="ts" setup>
const props = defineProps({
  lg: Boolean,
  md: Boolean,
  tight: Boolean,
  noPadding: Boolean,
  noTopBorderRadius: Boolean,
  noBgColor: Boolean,
})

const bgColor = computed(() => {
  if (props.noBgColor) {
    return 'bg-none'
  }

  return 'bgmode-900'
})

const borderRadiusClass = computed(() => {
  if (props.noTopBorderRadius) {
    return 'rounded-b-xl'
  }

  return 'rounded-3xl'
})

const padding = computed(() => {
  if (props.noPadding) {
    return 'p-0'
  }

  return {
    'p-4 lg:p-6': props.lg && !props.tight,
    'p-4': props.md && !props.tight,
    'p-2 lg:p-3': !props.md && !props.lg && !props.tight
  }
})
</script>

<template>
  <div :class="[borderRadiusClass, bgColor, padding]">
    <slot />
  </div>
</template>
